$width-content-max: 620px;
$width-body-max: 960px;
$width-main-max: 700px;
$width-nav-max: 800px;
$width-aside: 400px;
$width-nav: 80px;

$font-size-ui: 0.5rem;
$font-size-default: 21px;
$font-size-small: 0.9rem;
$font-size-smaller: 0.75rem;
$font-size-tiny: 0.65rem;
$font-size-large: 1.15rem;
$font-size-larger: 1.5rem;
$font-size-huge: 2rem;

$font-family-display: "content-body, Times, Times New Roman, serif";
$font-family-body: "content-body, Times, Times New Roman, serif";
$font-family-body-sc: "content-body-sc, content-body, Times, Times New Roman, serif";
$font-family-system: "'-apple-system', '.SFNSText-Regular', 'San Francisco', Roboto, 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif";
$font-family-mono: "Menlo, monospace";

$line-height-loose: 1.45;
$line-height-default: 1.4;
$line-height-tight: 1.3;
$line-height-tighter: 1.2;

$line-spacing-small: 0.4rem;
$line-spacing-default: 1.35rem;
$line-spacing-large: 2rem;
$line-spacing-larger: 4rem;

$screen-micro: "screen and (max-width: 320px)";
$screen-tiny: "screen and (max-width: 400px)";
$screen-small: "screen and (max-width: 660px)";
$screen-medium: "screen and (max-width: 980px)";

$letter-spacing-tight: -0.01em;
$letter-spacing-loose: 0.025em;
$letter-spacing-looser: 0.125em;
$letter-spacing-loosest: 0.2em;
