a {
  text-decoration: none;
  color: $color-text-default;
}
blockquote+blockquote {
  &:before {
    display: none;
  }
}
blockquote {
  margin: $line-spacing-default 0;
  padding-left: $line-spacing-large;
  padding-right: $line-spacing-large;
  font-size: $font-size-small;
  position: relative;
  // &:before {
  //   content: "“";
  //   font-size: 3rem;
  //   position: absolute;
  //   top: -1rem;
  //   left: 0;
  //   color: $color-line-light;
  // }
  @media #{$screen-medium} {
  }
  @media #{$screen-small} {
    padding-left: $line-spacing-default;
    padding-right: 0;
  }
  p {
    text-indent: 0 !important;
    margin-bottom: $line-spacing-default !important;
  }
  p:last-child {
    margin-bottom: 0;
  }
  cite {
    text-indent: 0;
    margin-top: $line-spacing-default;
    text-align: right;
  }
}
blockquote blockquote {
  border-left: 0;
  margin: 0;
  padding: 0;
  margin-left: $line-spacing-default;
  margin-bottom: $line-spacing-default;
  float: right;
  max-width: $width-content-max / 2;
  margin-right: -8vw;
  font-size: $font-size-small;
  p {
    color: $color-brand;
    font-size: $font-size-large;
    font-style: italic;
    line-height: $line-height-tight;
    font-feature-settings: "liga", "dlig", "hlig", "calt";
    cite {
      color: $color-text-default;
      font-size: 1rem;
      line-height: $line-height-default;
    }
  }
  @media #{$screen-medium} {
    text-align: center;
    margin-top: $line-spacing-large;
    max-width: 100%;
    float: none;
    padding-left: 0;
    border: 0;
    margin-left: -$line-spacing-large;
    position: relative;
    p {
      margin-right: -$line-spacing-large * 2;
    }
    &:after {
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      background: $color-background-default;
      position: absolute;
      left: -1px;
      top: 0;
    }
  }
  @media #{$screen-small} {
    p {
      margin-right: -$line-spacing-large;
    }
  }
}
#post blockquote:last-child blockquote {
  text-align: center;
  margin-top: $line-spacing-large;
  max-width: 100%;
  float: none;
  padding-left: 0;
  border: 0;
  margin-left: -$line-spacing-large;
  position: relative;
  p {
    margin-right: -$line-spacing-large;
  }
}
cite {
  display: block;
  font-style: normal;
  font-size: $font-size-small;
  // font-feature-settings: "smcp";
  // text-transform: lowercase;
  font-family: #{$font-family-body-sc};
  // letter-spacing: $letter-spacing-loose;
  i {
    font-family: #{$font-family-body};
    text-transform: none;
    letter-spacing: 0;
    font-style: italic;
    font-feature-settings: normal;
  }
}
figure {
  margin: $line-spacing-default 0;
  max-width: 100%;
}
figcaption,
.caption {
  color: $color-text-default;
  display: block;
  padding-left: 0;
  margin: -($line-spacing-default) 0 $line-spacing-default 0;
  font-style: italic;
  font-size: $font-size-smaller;
  a {
    color: $color-text-default;
  }
  @media #{$screen-tiny} {
    padding-left: $line-spacing-default;
  }
}

img {
  max-width: 100%;
  margin: $line-spacing-large 0;
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: $line-spacing-large 0 $line-spacing-default 0;
  font-weight: normal;
  font-style: normal;
  // text-align: center;
}
h2+ul,h2+ul,
h3+ol,h3+ol {
  margin-top: 0;
}
// .meta {
//   text-align: center;
// }
article {
  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child,
  > h6:first-child,
  > pre:first-child,
  > blockquote:first-child,
  > ol:first-child,
  > ul:first-child {
    margin-top: 0;
  }
}



h1 {
  margin: 0;
  line-height: $line-height-tighter;
  font-size: $font-size-huge;
  // word-spacing: -0.08em;
  letter-spacing: $letter-spacing-tight;
  font-feature-settings: "liga", "dlig", "hlig", "calt", "tnum", "cv11";
  // font-family: #{$font-family-display};
  // text-transform: uppercase;
  font-weight: 400;
}
header {
  position: relative;
  color: $color-text-default;
  // -webkit-text-fill-color: transparent;
  // background-image: linear-gradient(to bottom, #E3CD9A 0, #83533C 40%, #83533C 100%);
  // -webkit-background-clip: text;
  // background-attachment: fixed;
}

  // header h1 {
  //   position: relative;
  //   background: white;
  //   mix-blend-mode: multiply;

  // }
  // header h1::before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background: linear-gradient(to bottom, #E3CD9A 0, #604740 40%, #604740 100%);
  //   pointer-events: none;  
  //   background-attachment: fixed;

  // }
  // header h1::before {
  //   mix-blend-mode: screen;
  // }

header h1+h2 {
  text-transform: none;
  margin-bottom: 0;
  font-style: italic;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1rem;
  font-weight: normal;
  margin-top: 0.2em;
  // color: $color-brand;
}
// .centered {
//   h1,
//   h1+h2 {
//     text-align: center;
//     @media #{$screen-small} {
//       text-align: left;
//     }
//   }
// }
h2 {
  font-size: $font-size-large;
  line-height: $line-height-default;
  font-style: italic;
  font-feature-settings: "liga", "dlig", "hlig", "calt";
}
h3 {
  font-family: #{$font-family-body-sc};
  font-size: 1rem;
  line-height: $line-height-default;
  // font-style: italic;
  // font-feature-settings: "smcp";
  // letter-spacing: $letter-spacing-loose;
}
h4,
h5,
h6 {
  text-align: left;
}
hr {
  margin: $line-spacing-default auto;
  height: 0.6rem;
  line-height: 1rem;
  border: 0;
  padding: 0;
  text-align: center;
  &:before {
    content: "* * *";
    word-spacing: 0.25rem;
  }
}
p {
  margin: 0 0 $line-spacing-default 0;
  hanging-punctuation: first;
  hyphens: auto;
  -webkit-hyphens: auto;
}
.hyphenate {
  hyphens: auto;
  -webkit-hyphens: auto;
}
// article {
//   p {
//     margin: 0;
//   }
//   p+p {
//     text-indent: $line-spacing-default;
//     @media #{$screen-tiny} {
//       text-indent: $line-spacing-default;
//     }
//   }
// }
.img-only+p {
  text-indent: 0;
}
.is-poetry {
  // max-width: $width-content-max;
  p {
    margin: 0 0 $line-spacing-default 0;
    text-indent: 0;
  }
}
code {
  font-family: #{$font-family-mono};
  font-size: 0.85rem;
  letter-spacing: 0;
}
pre {
  letter-spacing: 0;
  font-family: #{$font-family-mono};
  line-height: $line-height-tight;
  padding: 0 $line-spacing-large;
  margin: $line-spacing-default 0;
  overflow-x: show;
  code {
    font-size: 0.75rem;
  }
}
strong {
  font-style: normal;
  font-weight: normal;
  font-family: #{$font-family-body-sc};
  // font-feature-settings: "smcp";
  // letter-spacing: $letter-spacing-loose;
}
strong {
  // text-transform: lowercase;
}

em {
  font-style: italic;
  font-feature-settings: "liga", "dlig", "hlig", "calt";
}
sup,
sub {
  font-feature-settings: "tnum", "cv11", "sups";
  font-size: $font-size-ui;
  font-weight: bold;
}
table {
  margin: $line-spacing-default 0;
}
ul,
ol {
  margin: $line-spacing-default 0;
  padding: 0;
  li {
    margin-left: $line-spacing-large;
    margin-right: 0;
    @media #{$screen-tiny} {
      margin-left: $line-spacing-default;
    }
  }
  ul,
  ol {
    margin: 0;
  }
}
ul {
  list-style-type: disc;
}
/* Footnotes */
article hr + ol:last-child, article hr + ul:last-child, .footnotes {
  font-size: $font-size-smaller;
  word-wrap: break-word;
  margin-top: 0;
  margin-bottom: 0;
  line-height: $line-height-loose;
  li {
    @media #{$screen-tiny} {
      margin-left: $line-spacing-default;
    }
  }
}
article hr + ul:last-child, .footnotes {
  list-style-type: none;
}
.footnotes {
  margin-top: $line-spacing-large;
}
// .footnotes a, .footnote {
//   background-image: none;
//   color: $color-brand;
//   &:hover {
//     background-image: none;
//     color: $color-text-default;
//   }
// }
.reversefootnote, .footnote {
  background-image: none;
  font-feature-settings: "tnum", "cv11";
  color: $color-text-default;
  &:after {
    display: none;
  }
  &:hover {
    background-image: none;
    color: $color-brand;
  }
}
