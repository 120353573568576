

@font-face {
    font-family: 'content-display';
    src: url(/fonts/orkney-bold-webfont.woff);
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'content-body';
    src: url(/fonts/equity-cyrillic.woff);
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'content-body';
    src: url(/fonts/equity-italic-webfont.woff);
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: 'content-body-sc';
    src: url(/fonts/equity-sc-webfont.woff);
    font-style: normal;
    font-weight: 400;
}