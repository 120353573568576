// $color-link: rgb(195, 180, 140);
// $color-brand: rgb(165, 145, 105);
   $color-brand: rgb(159,63,62);
// $color-brand: rgb(205, 180, 125);
// $color-gold: rgb(163,142,117);
// $color-brand: rgb(163,142,117);
// $color-brand: rgb(0,0,0);
$color-gold: #E3CC9D;
$color-brand-light: rgb(193,172,147);

// $color-brand: rgb(52, 97, 175);
$color-link: lighten(desaturate($color-brand, 20%), 20%);

$color-selection: rgba(255, 230, 100, 0.5);


$color-line-light: rgb(228,226,224);
$color-line-faint: rgba(0,0,0,0.08);
$color-text-default: rgb(10,10,10);
$color-text-light: rgba(0,0,0,0.7);
$color-text-lighter: rgba(0,0,0,0.3);
$color-text-overlay: rgba(255,255,255,0.4);
$color-background-default: rgb(255,255,255);
$color-background-light: rgb(248,246,244);
$color-background-dark: rgb(35,35,43);
$color-background-secondary: rgb(248,246,244);
