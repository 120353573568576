@import "fonts";
@import "animations";
@import "colors";
@import "typography";
@import "formatting";


html {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-default;
  font-family: #{$font-family-body};
  line-height: $line-height-default;
  color: $color-text-default;
  background-attachment: fixed;
  // font-feature-settings: "liga", "calt", "tnum", "cv11";
  font-feature-settings: "liga", "tnum", "calt", "cv11";

  @media screen and (max-width: 600px) {
    // font-size: $font-size-default - 1px;
  }
    @media screen and (max-width: 400px) {
    // font-size: $font-size-default - 1px;
    // line-height: $line-height-tight;
  }
  @media screen and (max-width: 360px) {
    font-size: $font-size-default - 2px;
    // line-height: $line-height-tight;
  }
}
html {
  overflow-x: hidden;
  width: 100%;
}
body {
  // overflow-x: hidden;
  background-color: $color-background-default;
  margin: 0 auto;
  font-size: 100%;
}
main {
  max-width: $width-main-max;
  float: left;
}

#side-borders {
  position: fixed;
  top: 0;
  left: 50%;
  height: 100%;
  width: $width-content-max + 122px;
  margin-left: -(($width-content-max + 122px) / 2);
  border-left: solid 1px $color-line-faint;
  border-right: solid 1px $color-line-faint;
  z-index: -1;
}

// .overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 380px;
//   height: 100%;
//   background-color: $color-background-default;
//   opacity: 0.7;
//   z-index: 101;
//   transition-duration: $duration-default;
//   &:hover {
//     opacity: 0;
//   }
// }
// #aside-bg {
//   position: fixed;
//   width: 400px;
//   height: 100%;
//   top: 0;
//   left: 0;
//   background: $color-background-dark;
//   z-index: 0;
// }





#logo {
  display: block;
  position: absolute;
  top: 0;
  left: 1rem;
  width: 25px;
  height: 50px;
  padding: 10px;
  background-color: $color-brand;
  z-index: 300;
  // box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  // border-radius: 0 0 1px 1px;
}


nav {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  // &:before {
  //   content: "";
  //   display: block;
  //   box-shadow: 0 0 1px rgba(0,0,0,0.1), 0 0 40px rgba(0,0,0,0.1);
  //   position: absolute;
  //   height: 100%;
  //   width: $width-content-max;
  //   top: 0;
  //   left: 50%;
  //   margin-left: -($width-content-max/2);
  // }
  // &:after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   background-color: rgba(255,255,255,0.98);
  //   top: 0;
  //   left: 0;
  // }
  a {
    // color: $color-background-default;
  }
  #nav-inner {
    line-height: $line-height-default;
    // max-width: $width-content-max;
    margin: 0 auto;
    font-size: $font-size-smaller;
    padding: 1rem $line-spacing-default;
    position: relative;
    z-index: 11;
    max-width: $width-body-max;
    #nav-site-title {
      text-align: center;
      font-style: italic;
      img {
        max-width: 100px;
        height: auto;
        margin: 0 auto;
        background-size: 300% 100%;
        background-image: linear-gradient(to right, #ddd, #fff, #ddd, #fff);
        -webkit-animation: logo-bg 8s infinite;
        -moz-animation: logo-bg 8s infinite;
        -o-animation: logo-bg 8s infinite;
        animation: logo-bg 8s infinite;
        animation-timing-function: linear;
        background-position: 0 0;
      }
      a {
        display: inline-block;
      }
      b {
        font-weight: normal;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-looser;
      }
    }
    #nav-site-links {
      display: none;
      position: absolute;
      top: 50%;
      right: $line-spacing-default;
      margin-top: -1rem;
      height: 2rem;
      line-height: 2rem;
      // font-family: #{$font-family-display};
      // font-weight: bold;
      // text-transform: uppercase;
      // letter-spacing: $letter-spacing-looser;
      // font-size: $font-size-ui;
      a {
        margin-left: 0.5rem;
        // color: $color-background-default;
        // background-position: 0 1.1em;
        // background-size: 100% 1px;
        // background-repeat: repeat-x;
        // background-image: linear-gradient(to top, $color-background-default, $color-background-default 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
      }
      #nav-menu-link {
        display: none;
        background-image: none;
        cursor: pointer;
        @media #{$screen-small} {
          display: inline-block;
        }
      }
      #nav-menu-links-inner {
        @media #{$screen-small} {
          display: none;
        }
      }
    }
  }
}





#links {
  @media #{$screen-medium} {
    display: none;
  }
}
#subscribe-link {
  position: absolute;
  top: 0;
  left: 1rem;
  height: 40px;
  line-height: 40px;
  padding: 0 1.2em;
  font-family: #{$font-family-display};
  color: $color-background-default;
  font-size: $font-size-ui;
  letter-spacing: $letter-spacing-loosest;
  text-transform: uppercase;
  font-weight: 500;
  // border: solid 1.5px $color-text-default;
  background-color: $color-text-default;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  // outline: solid 4px $color-background-default;

}
.button {
  display: inline-block;
  font-size: $font-size-small;
  font-family: #{$font-family-body-sc};
  text-transform: lowercase;
  font-size: $font-size-smaller;
  // letter-spacing: $letter-spacing-loosest;
  // font-weight: 600;
  padding: 0.5em 1em 0.6em 1em;
  border: solid 1px $color-text-default;
  &:hover {
    color: $color-brand;
  }
}


.is-showing-nav,
.is-showing-subscribe {
  overflow: hidden;
}
// .overlay {
//     position: fixed;
//     top: 81px;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     background: $color-background-default;
//     z-index: 100;
//     overflow-y: scroll;
// }
// .overlay-inner {
//   max-width: $width-content-max;
//   margin: 0 auto;
//   padding: $line-spacing-large 1rem 120px 1rem;
// }
.isShowingNav {
  #nav-overlay {
    display: block;
  }
}
#nav-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-background-default;
  z-index: 2000;
  a {
    display: block;
    max-width: $width-content-max;
    margin: 0 auto;
    // font-family: #{$font-family-body-sc};
    // font-weight: 600;
    // letter-spacing: $letter-spacing-loosest;
    // text-transform: lowercase;
    font-size: $font-size-smaller;
    padding: 0.8em $line-spacing-default;
    &:first-child {
      padding-top: 1.4rem;
      cursor: pointer;
    }
    &:hover {
      color: $color-brand;
    }
  }
}


#subscribe-overlay {
  display: none;
  h2 {
    margin-bottom: $line-spacing-large;
  }
}
.is-showing-subscribe #subscribe-overlay {
  display: block;
}

#subscription-link {
  font-family: #{$font-family-display};
  background: $color-text-default;
  color: white;
  display: inline-block;
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 40px;
  line-height: 40px;
  padding: 0 16px 0 0;
  cursor: pointer;
  font-size: $font-size-ui;
  z-index: 22;
  border-radius: 4px 3px 3px 4px;
  font-weight: 500;
  i {
    font-weight: 400;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: $color-brand;
    text-align: center;
    position: relative;
    margin-right: 16px;
    transition-duration: $duration-default;
    border-radius: 3px 0 0 3px;
  }
  span {
    transition-duration: $duration-short;
    transition-property: opacity;
  }
  &:hover {
    // i {
    //   transform: scale(1.25);
    //   -webkit-transform: scale(1.25);
    // }
  }
  @media #{$screen-small} {
    top: 0.95rem;
  }
  @media #{$screen-tiny} {
    .t_ot {
      display: none;
    }
  }
  @media #{$screen-micro} {
    // padding: 0;
    // border-radius: 4px;
    // i {
    //   margin-right: 0;
    //   border-radius: 3px;
    // }
    // .t_f {
    //   display: none;
    // }
  }
}
#subscription-icon {
  display: inline-block;
  margin-left: 0.3rem;
  position: relative;
  top: -2px;
  &:before,
  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 14px;
    background-color: $color-text-default;
    transition-duration: $duration-short;
    transition-property: transform;
    border-radius: 2px;
  }
  &:before {
    transform: rotate(0);
  }
  &:after {
    position: relative;
    top: -2px;
    transform: rotate(90deg);
  }
}
.is-showing-subscription #subscription-icon {
  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
}
.is-showing-menu #menu {
  display: block;
}
#menu {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  display: none;
  position: absolute;
  top: 70px;
  left: 2rem;
  width: 7.4rem;
  box-sizing: border-box;
  padding: 1rem;
  box-shadow: 0 6px 18px rgba(0,0,0,0.08), 0 1px 4px rgba(0,0,0,0.08), 0 0 1px rgba(0,0,0,0.08), inset 0 0 10px rgba(255,255,255,1);
  background: rgba(255,255,255,0.97);
  z-index: 100;
  border-radius: 0.5rem;
  a {
    font-size: $font-size-smaller;
    display: block;
    padding: 0.3rem 0;
    i {
      display: inline-block;
      width: 1rem;
      text-align: center;
      position: relative;
      top: 1px;
    }
    &:hover {
      color: $color-brand;
    }
  }
}




article a,
#latest a,
#subscription a,
#nav-site-links a,
.hero-caption a,
.underlined {
  // &:after {
  //   display: inline-block;
  //   font-style: normal;
  //   font-weight: normal;
  //   content: "";
  //   background-color: $color-brand;
  //   width: 5px;
  //   height: 5px;
  //   border-radius: 5px;
  //   // border: solid 2px $color-brand;
  //   position: relative;
  //   top: -0.62em;
  //   margin-left: 0.05em;
  //   font-size: 0.8em;
  //   position: relative;
  //   // display: inline-block;
  //   z-index: 1;
  //   // border-radius: 1px;
  // }
  text-decoration: none;
  // text-shadow: -1px -1px 0 $color-background-default, 1px -1px 0 $color-background-default, 1px 0 0 $color-background-default,  0 1px 0 $color-background-default, -1px 1px 0 $color-background-default, 1px 1px 0 $color-background-default;
  color: $color-text-default;
  background-position: 0 1.1em;
  background-size: 200% 2px;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, $color-gold 0, $color-gold 50%, $color-brand 50%, $color-brand 100%);
  transition-duration: 0.4s;
  // background-image: linear-gradient(to top, #FFDD6F, #FFDD6F 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0));
  // background-image: linear-gradient(to top, #FEE778, #FEE778 4px, rgba(0, 0, 0, 0) 4px, rgba(0, 0, 0, 0));
  // @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  //   background-image: linear-gradient(to top, $color-text-default, $color-text-default 0.5px, rgba(0, 0, 0, 0) 0.5px, rgba(0, 0, 0, 0));
  // }
  &:hover {
    background-position-x: -100%;
    // background-image: linear-gradient(to top, $color-text-default, $color-text-default 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
    color: $color-text-default;
    // background-color: $color-background-light;
    &:after {
      // border-color: $color-text-default;
      background-color: $color-text-default;
      // transform: translate3d(0,0,0) scale(1.4);
    }
    // color: $color-brand;
    // background-image: linear-gradient(to top, $color-brand, $color-brand 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
    // @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    //   background-image: linear-gradient(to top, $color-text-default, $color-text-default 0.5px, rgba(0, 0, 0, 0) 0.5px, rgba(0, 0, 0, 0));
    // }
  }
}
.basic-links a {
  &:after {
    display: none;
  }
}

// article em a,
// #latest em a,
// #subscription em a,
// .hero-caption a,
// em .underlined,
// figcaption a,
// .caption a,
// cite i a {
//   background-position: 0 0.94em;
// }
// ::selection {
//   text-shadow: none !important;
//   background: $color-selection;
// }
// ::-moz-selection {
//   text-shadow: none !important;
//   background: $color-selection;
// }

article {
  margin: 0;
  margin-left: 15%;
  max-width: $width-content-max;
  padding-left: $line-spacing-default;
  padding-right: $line-spacing-default;
  @media #{$screen-medium} {
    margin-left: auto;
    margin-right: auto;
  }
}

article.is-short {
  padding-top: 33vh;
}
article > p:first-child {
  // font-size: 1.1rem;
}

#post {
  position: relative;
  // position: relative;
  // max-width: $width-content-max;
  // margin: 0 0 $line-spacing-larger 3rem;
  // z-index: 10;
  // opacity: 0;
}

.hero+header {
  padding-top: 0;
}

header {
  // color: $color-background-default;
  // background-color: $color-text-default;
  // max-width: $width-content-max;
  margin: 0 0 $line-spacing-large 0;
  padding: 8rem $line-spacing-default $line-spacing-default $line-spacing-default;
  max-width: $width-content-max;
  margin-left: 15%;
  @media #{$screen-medium} {
    margin-left: auto;
    margin-right: auto;
  }
  h1,
  h2 {
  }
}
.date {
  // font-feature-settings: "smcp";
  font-family: #{$font-family-body-sc};
}



#mc-embedded-subscribe-form {
  padding-bottom: $line-spacing-default;
  padding-left: $line-spacing-large;
  @media #{$screen-small} {
    padding-left: 0;
  }
  input[type=email] {
    text-align: left;
    font-size: $font-size-small;
    font-family: #{$font-family-body};
    font-style: italic;
    padding: 0.5em 0;
    border: 0;
    min-width: 260px;
    margin-bottom: $line-spacing-default;
      background-position: 0 2.1em;
      background-size: 200% 1px;
      background-repeat: repeat-x;
      background-color: $color-background-default;
      background-image: linear-gradient(to right, $color-line-light 0, $color-line-light 50%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%);
      transition-duration: 0.4s;
    &:hover {
      background-position-x: -100%;
    }
    &:focus {
      outline: none;
      border-color: $color-text-default;
      background-position: -100% 2.1em;
    }
  }
  input[type=submit] {
    font-family: #{$font-family-body};
    // text-transform: uppercase;
    font-size: $font-size-small;
    font-weight: 400;
    border-radius: 0;
    position: relative;
    top: 0.5px;
    padding: 0.4em 0.8em;
    background-size: 200% 100%;
    background-repeat: repeat-x;
    background-color: white;
    border: 0;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
    color: $color-text-default;
    cursor: pointer;
    border-radius: 2px;
    &:hover {
      // color: $color-text-default;
    }
  }
}


#newsletter-link {
  position: absolute;
  top: 0;
  left: -130px;
  padding-top: $line-spacing-default;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  background-color: $color-brand;
  color: white;
  text-align: center;
  font-style: italic;
  display: block;
  line-height: $line-height-tight;
  strong {
    font-style: normal;
  }
}
#newsletter-wrapper {
  clear: both;
  padding: $line-spacing-larger $line-spacing-default;
  position: relative;
  margin: $line-spacing-larger 0 0 0;
  @media #{$screen-small} {
    padding: 4rem $line-spacing-default;
  }
}
#newsletter {
  font-size: $font-size-small;
  max-width: $width-content-max;
  margin: 0 auto;
  line-height: $line-height-default;
}
#blurb {
  padding: 0 $line-spacing-large;
  // -webkit-column-count: 2;
  // -moz-column-count: 2;
  // -ms-column-count: 2;
  // column-count: 2;
  line-height: $line-height-default;
  -webkit-column-gap: $line-spacing-default;
  -moz-column-gap: $line-spacing-default;
  -ms-column-gap: $line-spacing-default;
  column-gap: $line-spacing-default;
  page-break-inside: avoid; /* For Firefox. */
  -webkit-column-break-inside: avoid; /* For Chrome & friends. */
  break-inside: avoid; /* For standard browsers like IE. :-) */

  @media #{$screen-small} {
    padding: 0;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    -ms-column-count: 1;
    column-count: 1;
  }
}
#latest {
  max-width: $width-content-max;
  margin: 0 auto;
  padding: $line-spacing-default;
}
@media print {
  html {
    color: rgb(0,0,0);
    line-height: $line-height-default;
    font-size: $font-size-small;
  }
  h1+h2 {
    color: $color-text-default !important;
  }

  body {
    margin: 0 auto;
    padding: 0;
    max-width: 28em;
  }
  #post {
    opacity: 1;
  }
  blockquote blockquote p {
    color: $color-text-default;
  }
  article a {
    &:after {
      display: none;
    }
  }
  #aside-bg,
  .hero,
  .hero-caption,
  #newsletter,
  footer,
  nav,
  aside,
  #meta,
  #share {
    display: none;
  }
}



// strong:first-child .first-letter {
//   text-transform: none;
//   font-size: 2.9rem;
//   height: 2rem;
//   line-height: 2.5rem;
//   float: left;
//   display: inline-block;
//   position: relative;
//   top: 0.15em;
//   margin-right: 0.1rem;
//   font-family: #{$font-family-display};
// }
// strong:first-child .letter-a { margin-left: -0.4rem; }
// strong:first-child .letter-l { margin-left: -0.4rem; }




footer {
  user-slect: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: default;
  padding: $line-spacing-default;
  text-align: center;
  font-size: $font-size-tiny;
  color: $color-text-lighter;
}
#footer-inner {
  margin: 0 auto;
  max-width: $width-nav-max;
  position: relative;
  height: 60px;
  padding: 0 1rem;
}

#share {
  position: fixed;
  bottom: $line-spacing-default;
  right: $line-spacing-default;
  background-color: $color-text-default;
  color: white;
  font-family: #{$font-family-display};
  display: block;
  padding: 0.2em 0.5em;
  font-size: $font-size-small;
  box-shadow: 0 15px 25px rgba(0,0,0,0.2);
  z-index: 1000;
    display: none;
}
#meta {
  font-feature-settings: "smcp", "tnum";
  // font-style: italic;
  letter-spacing: $letter-spacing-loose;
  // font-family: #{$font-family-body-sc};
  max-width: $width-content-max;
  padding: 0 $line-spacing-default;
  margin: $line-spacing-large auto;
  text-align: center;
  font-size: $font-size-smaller;
  letter-spacing: $letter-spacing-looser;
  text-transform: lowercase;
}
.latest-posts {
  max-width: $width-content-max;
  margin: 0 auto;
  padding-top: $line-spacing-larger;
  padding-left: 1rem;
  box-sizing: border-box;
}
.latest {
  max-width: $width-nav-max / 3.4;
  float: left;
  margin-right: 1rem;
  margin-bottom: $line-spacing-large;
  .meta {
    position: relative;
    margin-bottom: 0.5rem;
  }
  h1 {
    font-size: $font-size-large;
    line-height: $line-height-tighter;
  }
  h2 {
    line-height: $line-height-tighter;
    font-size: $font-size-small;
  }
}
#intro-wrapper {
  padding: 120px 1rem 80px 1rem;
}
#intro {
  max-width: $width-content-max;
  margin: 0 auto;
  .title {
    color: white;
    font-size: $font-size-large;
    font-style: italic;
    line-height: $line-height-loose;
    margin-bottom: $line-spacing-default;
  }
  a {
    font-weight: bold;
    color: white;
    display: inline-block;
    height: 40px;
    padding: 0 10px;
    line-height: 40px;
    font-size: $font-size-ui;
    font-family: #{$font-family-display};
    background: $color-text-default;
    border-radius: 3px;
  }
  h2 {
    color: white;
  }
  input[type=email] {
    border-color: $color-text-overlay;
    color: $color-text-overlay;
  }
}
.meta {
  font-size: $font-size-ui;
  text-transform: lowercase;
  font-family: #{$font-family-body-sc};
  color: $color-text-light;
  margin-top: 0.2em;
}
.featured-posts {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: default;
  // &::after {
  //   content: "";
  //   display: block;
  //   clear: both;
  //   height: 1px;
  //   margin: 0 1rem;
  //   background: $color-line-faint;
  // }
}
.featured {
  max-width: $width-content-max;
  margin: 0 auto;
  display: block;
  height: auto !important;
  padding-bottom: 30px;


  h1 {
    text-align: left;
    margin: 1rem 0 0 0;
    padding: 0 1rem;
    font-size: $font-size-larger;
  }
  h2 {
    text-align: left;
    line-height: $line-height-tighter;
    padding: 0 1rem;
    font-size: $font-size-default;
  }
  .meta {
    padding: 0 1rem;
  }
}
.featured-0 {
  .featured-hero {
    margin-right: 0;
  }
}
.featured-1 {
  .featured-hero {
    margin-left: 0;
    // &:before {
    //   content: "";
    //   display: block;
    //   height: 100%;
    //   width: 1rem;
    //   top: 0;
    //   left: 0;
    //   position: absolute;
    //   background: $color-background-default;
    //   @media #{$screen-medium} {
    //     left: -0.5rem;
    //   }
    //   @media #{$screen-small} {
    //     display: none;
    //   }
    // }
  }
}


.hero,
.featured-hero {
  height: 50vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.featured-hero {
  height: 40vh;
}
.hero-caption {
  color: $color-text-light;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: default;
  max-width: $width-content-max;
  font-size: $font-size-smaller;
  font-style: italic;
}



#tweet-tooltip {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 40px;
  // box-shadow: 0 10px 20px rgba(0,0,0,0.15);
  font-size: 17px;
  font-family: #{$font-family-system};
  left: 0;
  top: 0;
  display: none;
  z-index: 11;
  color: $color-text-light;
  svg {
    z-index: 12;
    position: relative;
    max-width: 18px;
    max-height: 18px;
    top: 1px;
  }
  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background: $color-background-default;
    top: 0;
    left: 0;
    border-radius: 40px 40px 0 40px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    z-index: 11;
    box-shadow: 10px 10px 20px rgba(0,0,0,0.15);
  }
  i {
    color: #1da1f2;
    position: relative;
    z-index: 12;
  }
  // &:after {
  //   content: "";
  //   display: block;
  //   width: 0;
  //   height: 0;
  //   border-top: solid 17px $color-background-default;
  //   border-left: solid 17px transparent;
  //   border-right: solid 17px transparent;
  //   border-bottom: solid 17px transparent;
  //   position: absolute;
  //   bottom: -26px;
  //   left: 6px;
  // }
  &:hover {
    color: $color-brand;
  }
}

#debug {
  position: fixed;
  bottom: 10px;
  left: 10px;
  border: solid 1px red;
  z-index: 1000;
  font-size: 12px;
  font-family: monospace;
  padding: 5px;
}







#canvas-indicator {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 101;
  @media #{$screen-small} {
    right: -10px;
    bottom: -10px;
  }
}
#canvas-background {
  z-index: 100;
  content: "";
  display: block;
  position: fixed;
  right: 15px;
  bottom: 15px;
  height: 70px;
  width: 70px;
  border-radius: 70px;
  background: $color-background-default;
  @media #{$screen-small} {
    right: 5px;
    bottom: 5px;
  }
}
#archive {
  h2 {
    text-align: left;
    font-style: normal;
    font-size: $font-size-smaller;
    // text-transform: uppercase;
    letter-spacing: $letter-spacing-loose;
    // font-weight: 600;
    font-feature-settings: "liga", "tnum", "calt", "cv11";
  }
  h2:first-of-type {
    margin-top: 0;
  }
  a {
    color: $color-text-default;
    background-image: none;
    transition-duration: 0;
    &:hover {
      color: $color-brand;
    }
  }
}
// .basic-links {
//   a {
//     color: $color-brand;
//     &:hover {
//       color: $color-text-default;
//     }
//   }
//   a:after {
//     display: none;
//   }
// }

#andreyev {
  clear: both;
  position: relative;
  z-index: 200;
  font-size: $font-size-small;
  line-height: $line-height-default;
  padding: 4rem $line-spacing-default;
  // background-color: $color-background-light;
  // background-image: linear-gradient(to top, $color-background-light, rgb(255,255,255));
  margin-top: 1.5rem;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  img {
    position: absolute;
    top: -60px;
    left: -40px;
    max-width: 300px;
    height: auto;
  }
  #andreyev-inner {
    max-width: $width-content-max;
    margin: 0 auto;
    min-height: 240px;
    position: relative;
  }
  #andreyev-blurb {
    float: right;
    max-width: 237.5px;
  }
  #andreyev-blurb-desc {
  }
  #andreyev-quote {
    font-size: 1.1rem;
    font-style: italic;
    color: $color-brand;
  }
  @media screen and (max-width: ($width-content-max + 50px)) {
    img {
      position: absolute;
      left: 50%;
      top: -500px;
      margin-left: -175px;
    }
    #andreyev-blurb {
      float: none;
      margin: 450px auto 0 auto;
      position: relative;
      max-width: 100%;
    }
    #andreyev-inner {
    }
  }
}

#newsletter-page {
  border: solid 2px $color-text-default;
  max-width: $width-content-max;
  margin: 0 auto;
  padding: $line-spacing-larger;
  h1 {
    font-family: #{$font-family-display};
    font-style: normal;
    text-transform: uppercase;
    font-size: $font-size-large;
    letter-spacing: $letter-spacing-looser;
    text-align: center;
  }
  h2 {
    color: $color-text-default;
    text-align: center;
  }
}




.cover-shadow-1,
.cover-shadow-2,
.cover-shadow-3,
.cover-highlight-1,
.cover-highlight-2,
.cover-highlight-3,
.cover-highlight-4 {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 9;
  height: 100%;
}
.cover-shadow-1 {
  border-radius: 4px 0 0 4px;
  left: 0;
  width: 1.5%;
  background-image: linear-gradient(to right, rgba(0,0,0,0.8) 0, rgba(0,0,0,0.2) 95%, rgba(0,0,0,0) 100%);
  // background-image: radial-gradient(ellipse at center top, red 0, blue 150%);
}
.cover-shadow-2 {
  left: 1.8%;
  width: 1.8%;
  background-image: linear-gradient(to left, rgba(0,0,0,0.15), rgba(0,0,0,0));
}
.cover-shadow-3 {
  left: 3.6%;
  width: 1%;
  background-image: linear-gradient(to right, rgba(0,0,0,0.15), rgba(0,0,0,0));
}
.cover-highlight-1 {
  top: 0.1%;
  left: 1.4%;
  width: 0.7%;
  background-image: radial-gradient(ellipse at center top, rgba(255,255,255,0.08) 0, rgba(255,255,255,0) 70%);
}
.cover-highlight-2 {
  top: 0.15%;
  left: 1.5%;
  width: 1.5%;
  background-image: radial-gradient(ellipse at center top, rgba(255,255,255,0.05) 0, rgba(255,255,255,0) 100%);
}
.cover-highlight-3 {
  top: 0.15%;
  left: 4.4%;
  width: 0.8%;
  background-image: radial-gradient(ellipse at center top, rgba(255,255,255,0.04) 0, rgba(255,255,255,0) 100%);
}
.cover-highlight-4 {
  top: 0.15%;
  left: 3.3%;
  width: 2.5%;
  background-image: radial-gradient(ellipse at center top, rgba(255,255,255,0.1) 0, rgba(255,255,255,0) 20%);
}
.cover-highlight-5 {
  position: absolute;
  top: 0.1%;
  left: 3.7%;
  width: 1.6%;
  height: 0.4%;
  z-index: 9;
  background-image: radial-gradient(ellipse at center center, rgba(255,255,255,0.1) 0, rgba(255,255,255,0) 80%);
}
.cover-highlight-6 {
  position: absolute;
  top: 0.1%;
  left: 0.9%;
  width: 1.4%;
  height: 0.4%;
  z-index: 9;
  background-image: radial-gradient(ellipse at center center, rgba(255,255,255,0.1) 0, rgba(255,255,255,0) 80%);
}
.cover-shadow-top {
  position: absolute;
  width: 100%;
  z-index: 9;
  left: 0.7%;
  top: 0;
  height: 0.15%;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0));
}
.cover-shadow-bottom {
  position: absolute;
  width: 100%;
  z-index: 9;
  left: 0.7%;
  bottom: 0;
  height: 0.3%;
  background-image: linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0));
}
.cover-highlight-top {
  position: absolute;
  width: 100%;
  z-index: 9;
  left: 1.5%;
  top: 0.1%;
  height: 0.3%;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.1), rgba(255,255,255,0));
}
.cover-highlight-right {
  position: absolute;
  width: 0.3%;
  height: 100%;
  z-index: 9;
  top: 0;
  right: 0;
  background-image: linear-gradient(to left, rgba(255,255,255,0.05), rgba(255,255,255,0));
}
.cover-bloom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at center top, rgba(255,255,255,0.05) 0, rgba(255,255,255,0) 30%);
  z-index: 9;
  background-attachment: fixed;
}
.cover-effects {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 4px 3px 3px 4px;
  overflow: hidden;
}
.cover {
  max-width: $width-main-max;
  margin: 0 auto 1rem auto;
  padding: $line-spacing-large;
  h2 {
    font-style: normal;
    font-family: #{$font-family-display};
    margin: 0;
    text-align: left;
    line-height: $line-height-tight;
    font-size: $font-size-larger;
  }
  h3 {
    margin: 0;
    text-align: left;
    font-family: #{$font-family-display};
    text-transform: uppercase;
    letter-spacing: $letter-spacing-loosest;
    font-size: $font-size-smaller;
  }
  .cover-image {
    float: left;
  }
  .cover-blurb {
    max-width: $width-content-max / 1.5;
    float: right;
    font-size: $font-size-smaller;
    line-height: $line-height-tight;
  }
}
.available-at-amazon {
  max-height: 2.5rem;
  width: auto;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  top: 1.4rem;
}
$color-cover-link: #252525;
.cover-link {
  font-family: #{$font-family-display};
  display: inline-block;
  padding: 0.55em 1em;
  background-color: $color-cover-link;
  color: white;
  border-radius: 1px;
  box-shadow: 0 1px 0 darken($color-cover-link, 15%), 0 1px 2px rgba(0,0,0,0.28), 0 5px 15px rgba(0,0,0,0.4);
  text-shadow: 0 -1px 0 darken($color-cover-link, 15%);
  background-image: linear-gradient(to top, rgba(0,0,0,0.3), rgba(0,0,0,0));
  margin-bottom: 0.75em;
  font-size: $font-size-tiny;
}
.cover-image {
  position: relative;
  max-width: $width-content-max / 2.5;
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 0;
  box-shadow: 0 25px 100px rgba(0,0,0,0.25), 0 40px 40px rgba(0,0,0,0.15), 0 4px 10px rgba(0,0,0,0.25);
  border-radius: 4px 3px 3px 4px;
  // box-shadow: 0 -150px 100px rgba(0,0,0,0.1), 0 -80px 50px rgba(0,0,0,0.2), 0 -50px 1px rgba(0,0,0,0.2);
  img {
    z-index: 6;
    position: relative;
    border-radius: 4px 3px 3px 4px;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
  }
  &:after {
    border-radius: 4px 3px 3px 4px;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at top center, rgba(255,255,255,0.05) 0, rgba(255,255,255,0) 100%);
    z-index: 7;
    position: absolute;
    top: 0;
    left: 0;

      // background-color: #111;
  }
}
